import * as React from "react"
import styled from "styled-components"
import Meta from "../utilities/seo"
import HeroLight from "../microsites/agm/images/hero-v2.jpg"

const Main = styled.main`
  height: 100vh;
  position: relative;
  background-color: #0a0e15;
  color: #fff;
  font-size: 1.333125rem;
  font-family: var(--xn-font-body);
  font-style: normal;
  font-weight: 400;
  line-height: 2.1875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .link {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center;
    background: #001029;
  }

  @media (max-width: 600px) {
    background-color: #001029;

    img {
      object-fit: contain;
    }
    /* img {
      object-position: left;
      object-fit: contain;
    } */
  }
`

const Cta = styled.div`
  position: absolute;
  top: 2rem;
  right: 2rem;

  a {
    padding: 1.2rem 1.75rem;
    color: var(--c-mint-100);
    background-color: black;
    text-transform: uppercase;
    font-size: 0.85rem;
    font-weight: bold;
    letter-spacing: -0.2px;
  }
`

const Ele = () => {
  return (
    <>
      <Meta title="XENO AGM" />
      <Main>
        <Cta>
          <a
            href="https://us02web.zoom.us/webinar/register/WN_-CZa8hmTRuSOmZzn1MDsoQ"
            target="_blank"
            rel="noreferrer"
          >
            Register Now
          </a>
        </Cta>
        <a
          href="https://www.youtube.com/channel/UC55rEmJCVtqTMDBzRiFlATQ"
          target="_blank"
          rel="noreferrer"
          className="link"
        >
          <img src={HeroLight} alt="" />
        </a>
      </Main>
    </>
  )
}
export default Ele
